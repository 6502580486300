import { useCallback, useState } from 'react';
import { useBoolean, useIsMounted } from 'usehooks-ts';


const useFormNetworkError = (onSubmit: (_props: any) => Promise<any> = Promise.reject) => {
    const [error, setError] = useState<any>(undefined);
    const isMounted = useIsMounted();
    const submitSucceeded = useBoolean(false);

    const reset = useCallback((newError: any = undefined) => {
        if (isMounted()) {
            setError(newError);
            submitSucceeded.setFalse();
        }
    }, [submitSucceeded, isMounted]);

    const handleSubmit = useCallback((args: any) => {
        reset();
        return onSubmit(args)
            .then(res => {
                if (isMounted())
                    submitSucceeded.setTrue();
                return res;
            })
            .catch(networkError => {
                if (isMounted()) {
                    setError(networkError);
                    throw networkError;
                }
            });
    }, [onSubmit, reset, submitSucceeded, isMounted]);

    return {
        error,
        handleSubmit,
        reset,
        submitSucceeded: submitSucceeded.value
    };
};

export default useFormNetworkError;