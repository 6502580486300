import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormActionButton from 'components/form/FormActionButton';
import FormTypographyField from 'components/form/FormTypographyField';
import FormCard, { type ActionDialogStateHandle, type TField } from 'components/form/FVFormCard';
import PatternClaim from 'components/patterns/patternClaim';
import PatternRight from 'components/patterns/PatternRight';
import useTranslation, { useSelectedLanguage } from 'hooks/useTranslation';
import { useEffect, useRef, type ReactElement } from 'react';
import NavLink from 'shared/components/menu/NavLink';

const LoginRegisterLinkButton = ({ href, ...args }: any) => {

    return (
        <NavLink href={href}>
            <FormActionButton {...args}/>
        </NavLink>
    );
};


type FormDataShapeProps = {
    text1: string,
    text2: string,
    text3: string
};
interface LoginRegisterCardProps {
    children?: ReactElement,
    redirectUri?: string
}

const LoginRegisterCard = ({ children, redirectUri, ...props }: LoginRegisterCardProps) => {
    const { t } = useTranslation();
    const { lang } = useSelectedLanguage();
    const formRef = useRef<ActionDialogStateHandle<FormDataShapeProps>>(null);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.update();
        }
    }, [lang]);

    return (
        <>
            <Stack
                display={'flex'}
                justifyContent={'center'}
                alignItems={'stretch'}
                sx={{
                    position: 'relative'
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        opacity: 0.10,
                        width: '30%',
                        maxWidth: 100,
                        overflow: 'hidden',
                        height: '100%',
                        ['& svg']: {
                            width: '100%',
                            height: 'auto',
                            display: 'block'
                        }
                    }}
                >
                    <PatternClaim color='neutral' />
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        opacity: 0.10,
                        width: { xs:'60%', sm:'35%' },
                        maxWidth: 500,
                        overflow: 'hidden',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'bottom',
                        ['& svg']: {
                            width: '100%',
                            height: 'auto',
                            display: 'block',
                            position: 'relative',
                            bottom: { xs: -120, sm: -100 },
                            right: -15
                        }
                    }}
                >
                    <PatternRight color='neutral' />
                </Box>
                <FormCard<FormDataShapeProps>
                    CardProps={{
                        variant: 'elevation',
                        backgroundColor: 'primary.400'
                    }}
                    CardContentProps={{
                        sx: {
                            borderTop: 'none',
                            py: 0
                        }
                    }}
                    header={{
                        title: t('pages.login.register_card.title'),
                        titleTypographyProps: {
                            variant: 'h2',
                            align: 'center',
                            fontWeight: 400,
                            sx: {
                                color: 'background.default'
                            }
                        },
                        sx: {
                            py: 0,
                            my: 0
                        }
                    }}
                    fields={[[
                        {
                            name: 'text1',
                            TextFieldProps: {
                                component: FormTypographyField,
                                text: t('pages.login.register_card.text1'),
                                sx: {
                                    color: 'background.default'
                                },
                                align: 'center',
                                variant: 'body'
                            }
                        },
                        {
                            name: 'text2',
                            TextFieldProps: {
                                component: FormTypographyField,
                                text: t('pages.login.register_card.text2'),
                                sx: {
                                    color: 'background.default'
                                },
                                align: 'center',
                                variant: 'body'
                            }
                        },
                        {
                            name: 'text3',
                            TextFieldProps: {
                                component: FormTypographyField,
                                text: t('pages.login.register_card.text3'),
                                sx: {
                                    color: 'background.default'
                                },
                                align: 'center',
                                variant: 'body'
                            }
                        }
                    ]] as TField<{ [key: string]: unknown; }>[][]}
                    readOnly={true}
                    actions={{
                        register: {
                            text: t('pages.login.register_card.submit'),
                            variant: 'contained',
                            color: 'secondary',
                            size: 'large',
                            order: 1,
                            type: 'submit',
                            component: LoginRegisterLinkButton,
                            href: `/register${![undefined, null, ''].includes(redirectUri) ? 
                                `?redirectUri=${encodeURIComponent(redirectUri as string)}` : 
                                ''
                            }`,
                            TextProps: {
                                fontWeight: 500
                            }
                        }
                    }}
                    CardActionsProps={{
                        sx: {
                            my: 1
                        }
                    }}
                    {...props}
                    ref={formRef}
                />
            </Stack>
        </>
    );
};

export default LoginRegisterCard;
export type {
    LoginRegisterCardProps
};
