import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { ForwardedRef, forwardRef, ReactElement, type SyntheticEvent, useCallback } from 'react';
import FormField, { type FormFieldProps } from './FVFormField';
import { useBoolean } from 'usehooks-ts';

const FormPasswordField = ({ ...props }: FormFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const showPassword = useBoolean(false);

    const handleMouseDownPassword = useCallback((event: SyntheticEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }, []);

    return (
        <ClickAwayListener onClickAway={showPassword.setFalse}>
            <Box width={'100%'}>
                <FormField 
                    autoComplete={'current-password'}
                    {...props}
                    type={ showPassword.value ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position={'end'}>
                            <IconButton
                                tabIndex={-1}
                                //ariaLabel={"toggle password visibility"}
                                disabled={props?.disabled || props?.readOnly}
                                onClick={showPassword.toggle}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword.value ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>
                    }
                    ref={ref}
                />
            </Box>
        </ClickAwayListener>
    );
};


const FormPasswordFieldWithRef = forwardRef(FormPasswordField) as
    (p: FormFieldProps & { ref?: ForwardedRef<HTMLInputElement> }) => ReactElement;


export default FormPasswordFieldWithRef;
export {
    FormPasswordFieldWithRef as FormPasswordField
};

