import Alert, { type AlertProps } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import FormActionButton, { type FormActionButtonProps } from 'components/form/FormActionButton';
import { forwardRef, type ForwardedRef, type ReactElement } from 'react';

interface FormErrorButtonProps extends FormActionButtonProps {
    children?: ReactElement,
    AlertProps?: AlertProps
}

const FormErrorButton = ({ text, children, severity = 'error', AlertProps, error, ...props }: FormErrorButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {

    return (
        <>
            <Collapse in={![undefined, null, ''].includes(error)} mountOnEnter={true} unmountOnExit={true}>
                {![undefined, null, ''].includes(error) ? //Sinon affiche texte d'erreur avant texte de success TODO (pour  plus tard) revoir le comportement
                    <Box flex={1} display={'flex'} alignItems={'center'}>
                        <Fade in={true}>
                            <FormActionButton
                                color={ severity }
                                fullWidth={true}
                                elevation={0}
                                component={Alert}
                                variant={'outlined'}
                                severity={severity}
                                icon={false}
                                {...props}
                                text={text}
                                ref={ref}
                                sx={{
                                    border: 0,
                                    cursor: 'default',
                                    textTransform: 'none',
                                    justifyContent: 'center',
                                    ['&:hover']: {
                                        border: 0,
                                        backgroundColor: 'transparent'
                                    },
                                    ['& .MuiTouchRipple-root']: {
                                        display: 'none'
                                    },
                                    ...props?.sx
                                }}
                            />
                        </Fade>
                    </Box>
                    : null
                }
            </Collapse>
        </>
    );
};

const FormErrorButtonWithRef = forwardRef(FormErrorButton) as
    (p: FormErrorButtonProps & { ref?: ForwardedRef<HTMLButtonElement> }) => ReactElement;

export default FormErrorButtonWithRef;
export type {
    FormErrorButtonProps
};

