import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import LoginForm from 'components/login/LoginForm';
import AskResetForm from 'components/login/AskResetForm';
import rules from 'components/form/validationSchemaRules';
import { useBoolean } from 'usehooks-ts';

interface LoginCardContainerProps {
    onSubmitLogin: (data: any) => any,
    onSubmitAskReset : (data: any) => any,
    loginSubmitLoading?: boolean,
    askResetSubmitLoading?: boolean
}

const LoginContainer = ({ onSubmitLogin, onSubmitAskReset, loginSubmitLoading, askResetSubmitLoading, ...props }: LoginCardContainerProps) => {
    //Toggle between login card and reset password card
    const loginFormSelected = useBoolean(true);

    return (
        <>
            <Stack
                direction={'column'}
                justifyContent={'center'}
                alignItems={'stretch'}
                {...props}
            >
                <Collapse in={loginFormSelected.value}>
                    <Box>
                        <Fade in={loginFormSelected.value}>
                            <Box>
                                <LoginForm
                                    onSubmit={onSubmitLogin}
                                    onSwitchClick={loginFormSelected.toggle}
                                    getValidationRule={() => rules.stringRequired}
                                    submitLoading={loginSubmitLoading}
                                />
                            </Box>
                        </Fade>
                    </Box>
                </Collapse>
                <Collapse in={!loginFormSelected.value} mountOnEnter={true} unmountOnExit={true}>
                    <Box>
                        <Fade in={!loginFormSelected.value}>
                            <Box>
                                <AskResetForm
                                    onSubmit={onSubmitAskReset}
                                    onSwitchClick={loginFormSelected.toggle}
                                    getValidationRule={() => rules.email}
                                    submitLoading={askResetSubmitLoading}
                                />
                            </Box>
                        </Fade>
                    </Box>
                </Collapse>
            </Stack>
        </>
    );
};

export default LoginContainer;
export type {
    LoginCardContainerProps
};
