import Link from '@mui/material/Link';
import FormCard, { type ActionDialogStateHandle, type FormCardProps } from 'components/form/FVFormCard';
import FormPasswordField from 'components/form/FVFormPasswordField';
import FormErrorButton from 'components/layout/FormErrorButton';
import { type LoginProps } from 'contexts/AuthContext';
import useFormNetworkError from 'hooks/useFormNetworkError';
import useTranslation from 'hooks/useTranslation';
import { useCallback, useRef } from 'react';

interface LoginFormProps extends FormCardProps<LoginProps> {
    onSwitchClick: () => void,
    submitLoading?: boolean
}

const LoginForm = ({ onSwitchClick, submitLoading, onSubmit, ...props }: LoginFormProps) => {
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogStateHandle<LoginProps>>(null);

    const { error, handleSubmit: parentHandleSubmit, reset } = useFormNetworkError(onSubmit);

    const handleSwitchClick = useCallback(() => {
        reset();
        return onSwitchClick();
    }, [onSwitchClick, reset]);

    const handleSubmit = useCallback((args: any) => {
        return parentHandleSubmit(args)
            .catch((res) => {
                if (res?.error == 'invalid_grant') {
                    return Promise.reject({
                        errors: {
                            username: ['validation.invalid_grant'],
                            password: ['validation.invalid_grant']
                        },
                        message: 'layout.error.network.422',
                        severity: 'error'
                    });
                }
                return Promise.reject(res);
            });
    }, [parentHandleSubmit]);

    const onSubmitClick = useCallback(() => {
        Promise.resolve()
            .then(() => actionRef?.current?.clearErrors())
            .then(() => actionRef?.current?.trigger())
            .then(() => reset(actionRef?.current?.errors));
    }, [reset]);

    const handleChange = useCallback((args: any) => {
        if (actionRef?.current) {
            actionRef?.current.clearErrors();
        }
        reset();
        return args;
    }, [reset]);

    return (
        <>
            <FormCard
                CardProps={{
                    FormProps: {
                        noValidate: false
                    },
                    variant: 'elevation'
                }}
                CardContentProps={{
                    sx: {
                        borderTop: 'none',
                        p: 0
                    }
                }}
                header={{
                    title: t('pages.login.login_card.title'),
                    titleTypographyProps: {
                        variant: 'h2',
                        align: 'center',
                        sx: {
                            color: 'primary.700',
                            mb: 1
                        }
                    },
                    subheader: t('pages.login.login_card.subtitle'),
                    subheaderTypographyProps: { align: 'center' },
                    sx: {
                        my: 0
                    }
                }}
                fields={[
                    [
                        {
                            label: t('models.user.data.email'),
                            name: 'username',
                            type: 'email'
                        },
                        {
                            label: t('models.user.data.password.password'),
                            name: 'password',
                            component: FormPasswordField
                        }
                    ]
                ]}
                data={{ username: '', password: '' }}
                actions={{
                    error: {
                        error: Object.keys(error || {}).length > 0 ? error : actionRef?.current?.errors,
                        text: t(`${[400].includes(error?.statusCode) ?
                            'pages.login.login_card.error.invalid_grant' :
                            (error?.error || error?.message)}`, t('layout.form.error')),
                        order: 2,
                        component: FormErrorButton,
                        isAllowed: Object.keys(error || {}).length > 0,
                        GridProps: {
                            xs: 12,
                            width:
                                '100%'
                        },
                        sx: {
                            justifyContent: 'center'
                        }
                    },
                    switch: {
                        text: t('pages.login.login_card.reset'),
                        fullWidth: true,
                        variant: 'text',
                        order: 3,
                        onClick: handleSwitchClick,
                        disableRipple: true,
                        sx: {
                            backgroundColor: 'transparent',
                            ['&:hover']: {
                                backgroundColor: 'transparent'
                            },
                            mt: -1
                        },
                        TextProps: {
                            fontSize: 14,
                            component: Link,
                            underline: 'hover',
                            variant: 'caption'
                        }
                    },
                    submit: {
                        text: t('pages.login.login_card.submit'),
                        variant: 'contained',
                        size: 'large',
                        order: 1,
                        type: 'submit',
                        loading: submitLoading,
                        onClick: onSubmitClick,
                        TextProps: {
                            color: 'white'
                        },
                        GridProps: { xs: 12, display: 'flex', justifyContent: 'center' }
                    }
                }}
                CardActionsGridProps={{
                    sx: { flexDirection: 'column ' },
                    alignItems: 'stretch',
                    justifyContent: 'center'
                }}
                CardActionsGridItemProps={{
                    justifyContent: 'center'
                }}
                onSubmit={handleSubmit}
                onChange={handleChange}
                {...props}
                ref={actionRef}
            />
        </>
    );
};

export default LoginForm;
export type {
    LoginFormProps
};

