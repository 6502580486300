import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import FormCard, { type ActionDialogStateHandle, type FormCardProps } from 'components/form/FVFormCard';
import FormErrorButton from 'components/layout/FormErrorButton';
import { type AskResetPasswordProps } from 'contexts/AuthContext';
import useFormNetworkError from 'hooks/useFormNetworkError';
import useTranslation from 'hooks/useTranslation';
import { useCallback, useRef } from 'react';

interface AskResetFormProps extends FormCardProps<AskResetPasswordProps> {
    onSwitchClick: () => void,
    submitLoading?: boolean
}

const AskResetForm = ({ onSwitchClick, submitLoading, onSubmit, ...props }: AskResetFormProps) => {
    const { t } = useTranslation();

    const actionRef = useRef<ActionDialogStateHandle<AskResetPasswordProps>>(null);
    const onSubmitWrapped = useCallback((args: any) => {
        return (onSubmit ? onSubmit(args) : Promise.reject('param onSubmit missing'))
            .catch((err: Error) => {
                actionRef?.current?.update({ username: '', password: '' });
                throw err;
            });
    }, [onSubmit]);
    const { error, handleSubmit, reset, submitSucceeded } = useFormNetworkError(onSubmitWrapped);

    const handleSwitchClick = useCallback(() => {
        reset();
        return onSwitchClick();
    }, [onSwitchClick, reset]);


    const handleChange = useCallback((args: any) => {
        if (actionRef?.current) {
            actionRef?.current.clearErrors();
        }
        reset();
        return args;
    }, [reset]);

    return (
        <>
            <Stack
                display={'flex'}
                justifyContent={'center'}
                alignItems={'stretch'}
            >
                <FormCard
                    CardProps={{
                        FormProps: {
                            noValidate: false
                        },
                        variant: 'elevation'
                    }}
                    header={{
                        title: t('pages.login.ask_reset_card.title'),
                        titleTypographyProps: {
                            align: 'center'
                        },
                        sx: {
                            my: 0
                        }
                    }}
                    CardContentProps={{
                        sx: {
                            borderTop: 'none'
                        }
                    }}
                    fields={[
                        [
                            {
                                label: t('models.user.data.email'),
                                name: 'email',
                                type: 'email'
                            }
                        ]
                    ]}
                    data={{ email: '' }}
                    actions={{
                        error: {
                            error: Object.keys(error || {}).length > 0 ? error : actionRef?.current?.errors,
                            isAllowed: submitSucceeded || Object.keys(error || {}).length > 0,
                            text: submitSucceeded ?
                                t('pages.login.ask_reset_card.success', '') :
                                t( `pages.login.${[400].includes(error?.statusCode) ? 'login_card.error.invalid_grant' : 'ask_reset_card.error.not_sent'}`, t(error?.error || error?.message)),
                            severity: submitSucceeded ? 'success' : 'error',
                            order: 2,
                            component: FormErrorButton,
                            GridProps: {
                                xs: 12,
                                width: '100%',
                                sx: { mt: -2 }
                            }
                        },
                        switch: {
                            text: t('pages.login.ask_reset_card.back'),
                            variant: 'text',
                            order: 3,
                            onClick: handleSwitchClick,
                            disableRipple: true,
                            sx: {
                                backgroundColor: 'transparent',
                                ['&:hover']: {
                                    backgroundColor: 'transparent'
                                },
                                mt: -1
                            },
                            TextProps: {
                                fontSize: 14,
                                component: Link,
                                underline: 'hover',
                                variant: 'caption'
                            }
                        },
                        submit: {
                            text: t('pages.login.ask_reset_card.submit'),
                            variant: 'contained',
                            size: 'large',
                            order: 1,
                            type: 'submit',
                            loading: submitLoading,
                            TextProps: {
                                color: 'white'
                            }
                        }
                    }}
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                    CardActionsGridProps={{
                        direction: 'column'
                    }}
                    {...props}
                    ref={actionRef}
                />
            </Stack>
        </>
    );
};

export default AskResetForm;
export type {
    AskResetFormProps
};
