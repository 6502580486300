import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import LoginContainer from 'components/login/LoginContainer';
import LoginRegisterCard from 'components/login/LoginRegisterCard';
import useApi from 'hooks/useApi';
import { useCallback } from 'react';
import { useBoolean, useIsMounted } from 'usehooks-ts';
import Box from '@mui/material/Box';
import { useRouter } from 'next/router';

const Login = () => {
    const { login, askResetPassword } = useApi();
    const router = useRouter();
    const { redirectUri } = router.query;

    const loginSubmitLoading = useBoolean(false);
    const askResetSubmitLoading = useBoolean(false);
    const isMounted = useIsMounted();

    const handleSubmitLogin = useCallback(
        (data: any) => {
            loginSubmitLoading.setTrue();
            return login({ ...data, redirectUri })
                .finally(() => {
                    if (isMounted()) loginSubmitLoading.setFalse();
                });
        },
        [loginSubmitLoading, login, isMounted, redirectUri]
    );

    const handleSubmitAskReset = useCallback(
        (data: any) => {
            askResetSubmitLoading.setTrue();
            return askResetPassword({ ...data })
                .finally(() => {
                    if (isMounted()) askResetSubmitLoading.setFalse();
                });
        },
        [
            askResetPassword,
            askResetSubmitLoading,
            isMounted
        ]
    );

    return (
        <>
            <Stack
                flex={1}
                direction={{ xs: 'column', md: 'row' }}
                alignItems={'center'}
                justifyContent={{ xs: 'flex-start', md: 'center' }}
                spacing={{ xs: 4, md: 2 }}
                mx={2}
            >
                <Container maxWidth={ 'lg' } sx={{ mb: 8, mt: { xs: 2, md: 8 }, px: { xs: 2, md: 0 } }} disableGutters={true}>
                    <Stack
                        flex={1}
                        direction={{ xs: 'column', md: 'row' }}
                        alignItems={{ xs: 'center', md: 'stretch' }}
                        justifyContent={{ xs: 'flex-start', md: 'center' }}
                        spacing={{ xs: 4, md: 2, lg: 8 }}
                    >
                        <Container maxWidth={'md'} fixed={true} disableGutters={true} sx={{ display: 'inline-flex' }}>
                            <Box flex={1}>
                                <LoginContainer
                                    onSubmitLogin={handleSubmitLogin}
                                    loginSubmitLoading={loginSubmitLoading.value}
                                    onSubmitAskReset={handleSubmitAskReset}
                                    askResetSubmitLoading={askResetSubmitLoading.value}
                                />
                            </Box>
                        </Container>
                        <Container maxWidth={'md'} fixed={true} disableGutters={true} sx={{ display: 'inline-flex' }}>
                            <Box flex={1}>
                                <LoginRegisterCard
                                    redirectUri={redirectUri as string}
                                />
                            </Box>
                        </Container>
                    </Stack>
                </Container>
            </Stack>
        </>
    );
};

const getStaticProps = async () => {
    return {
        props: {
            layoutProps: {
                headerType: 'return',
                mobileFooter: true,
                desktopFooter: true,
                PageProtectProps: {
                    needUnauth: true,
                    redirectUri: '/account',
                    returnCurrentPageAfterLogin: false
                }
            }
        }
    };
};

export default Login;
export { getStaticProps };
