import { type ForwardedRef, forwardRef, type ReactElement } from 'react';
import { type FormFieldProps } from './FVFormField';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import Stack, { type StackProps } from '@mui/material/Stack';

type FormTypographyExtendFrom = TypographyProps & Partial<FormFieldProps>;
interface FormTypographyFieldProps extends FormTypographyExtendFrom {
    children?: ReactElement,
    StackProps?: StackProps
}

const FormTypographyField = ({ children, value, text, onChangeBoolean, register, StackProps, TextFieldProps, inputProps, ...props }: FormTypographyFieldProps, ref?: ForwardedRef<HTMLElement>) => {
    return (
        <>
            <Stack
                flex={1}
                direction={'column'}
                alignItems={'flex-start'}
                justifyContent={'center'}
                {...StackProps}
            >
                <Typography
                    width={'100%'}
                    {...props}
                    ref={ref}
                >
                    {value || text}
                </Typography>
                {children}
            </Stack>
        </>
    );
};

const FormTypographyFieldWithRef = forwardRef(FormTypographyField) as
    (p: FormTypographyFieldProps & { ref?: ForwardedRef<HTMLElement> }) => ReactElement;

export default FormTypographyFieldWithRef;
export type {
    FormTypographyFieldProps
};
